* {
  margin: 0px;
  padding: 0px;
}

.main {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  background: rgb(230, 240, 250);
  font: 14px "Century Gothic", Futura, sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  padding: 5px;
}

.infopanel {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: 10px;
}

.infoitem {
  flex-basis: 30%;
}

.leaflet-container {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 600px;
  border: 1px solid #999;
}
